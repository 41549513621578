import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withSizes } from 'react-sizes';
import { isUserLoggedSelector } from 'common/selectors/user';
import {
  getIsB2BLockedSelector,
  getImagePlaceholderSelector,
  getIsB2BSelector
} from 'common/selectors';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

import { getMapSizesToPropsCustom, gotoRoute } from 'common/utils';

import loadable from '@loadable/component';
import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_CLICK } from 'common/constants/GTMConstants';
const ProductCardVariant = loadable(props =>
  import(`common/blocks/product/card/${props.blockVariant}`)
);

const ProductCard = ({
  t,
  theme,
  className,
  data,
  imagePlaceholder,
  imageWidth,
  imageHeight,
  isBig = false,
  showDiscount = false,
  showBrand = true,
  showLabels = true,
  lazyload = true,
  hasBoxShadow = true,
  layout,
  isLocked = false,
  isSmallScreen = false,
  blockSettings,
  isB2B,
  dispatchGoToDetail,
  GTMCategoryList = '',
  ...rest
}) => {
  const productCardData = {
    t: t,
    isB2B,
    theme: theme,
    className: className,
    data,
    imagePlaceholder,
    imageWidth,
    imageHeight,
    isBig,
    showDiscount,
    showBrand,
    showLabels,
    lazyload,
    hasBoxShadow,
    layout,
    isLocked,
    isSmallScreen
  };

  const onClickCallback = React.useCallback(e => {
    const urlTitle = _.get(data, 'urlTitle', null);
    if (e) {
      e.preventDefault();
      const target =
        e.ctrlKey || e.metaKey || (e.button && e.button === 1)
          ? '_blank'
          : '_self';
      fireGTMEvent(PRODUCT_CLICK, [data], GTMCategoryList);
      if (urlTitle) {
        dispatchGoToDetail(urlTitle, target);
      }
    }
  });

  return (
    <ProductCardVariant
      blockVariant={blockSettings.blockVariant}
      onClickCallback={onClickCallback}
      {...productCardData}
      {...rest}
    />
  );
};

const makeMapStateToProps = () => {
  const isUserLogged = isUserLoggedSelector();
  const imagePlaceholderSelector = getImagePlaceholderSelector();
  const isB2BLockedSelector = getIsB2BLockedSelector();
  const isB2BSelector = getIsB2BSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'ProductCardVariant'
  );
  return (state, props) => {
    return {
      imagePlaceholder: imagePlaceholderSelector(state, props),
      isLocked:
        isB2BLockedSelector(state, props) && !isUserLogged(state, props),
      isB2B: isB2BSelector(state, props),
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchGoToDetail: (url, target = '_self') => {
      dispatch(gotoRoute('product', { id: url }, {}, { target: target }));
    }
  };
};

const ProductCardConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ProductCard);

export default withTranslation()(
  withTheme(withSizes(getMapSizesToPropsCustom())(ProductCardConnected))
);
